import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css'; // Make sure to import your CSS file

axios.defaults.baseURL = process.env.REACT_APP_API_URL

const baseURL = axios.defaults.baseURL;

function App() {
    const [items, setItems] = useState([]);
    const [itemName, setItemName] = useState('');
    const [accessGranted, setAccessGranted] = useState(false); // Use state to track access


    useEffect(() => {
        const enteredPassword = prompt("Password");
        if (enteredPassword === process.env.REACT_APP_ACCESS_PW) {
            setAccessGranted(true);
            fetchItems();
        } else {
            alert("Unauthorized access. Incorrect password."); // Inform the user about unauthorized access
            setAccessGranted(false);
        }
    }, []);

     // Find the max votes
    const maxVotes = Math.max(...items.map(item => item.votes), 0);

    const fetchItems = async () => {
        if (!accessGranted) return;
        const result = await axios.get(`https://${baseURL}/items`, {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`
            }
          });
        setItems(result.data);
      
    };

    const voteForItem = async (id) => {
        if (!accessGranted) return; // Guard clause
        await axios.post(`https://${baseURL}/items/vote/${id}`, id, {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`
            }
          });
        fetchItems();
    };

    const addItem = async (event) => {
        event.preventDefault();
        if (!accessGranted || !itemName) return; // Guard clause
        await axios.post(`https://${baseURL}/items`, { name: itemName }, {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`
            }
          });
        setItemName('');
        fetchItems();
    };

    const deleteItem = async (id, votes) => {
      if (!accessGranted) return; // Guard clause
      let isAllowedToDelete = true;

      if (votes === maxVotes && maxVotes > 0) {
          const enteredPassword = prompt("This item has the most votes. Enter the special password to delete:");
          isAllowedToDelete = enteredPassword === process.env.REACT_APP_ITEM_PW;
      }

      if (isAllowedToDelete) {
          // Perform the delete operation (e.g., axios call to your backend)
          await axios.delete(`https://${baseURL}/items/${id}`, {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`
            }
          });
          console.log("Item deleted:", id); // Replace with actual delete logic
          // Optionally, refetch or update the items list to reflect the deletion
          fetchItems(); // Refresh items to reflect the deletion
      } else {
          alert("Incorrect password. The item cannot be deleted.");
      }
  };

  // Conditional rendering based on accessGranted state
if (!accessGranted) {
    return (
        <div className='app'>
            <h1>Unauthorized</h1>
            <p>You do not have access to this page. Please enter the correct password.</p>
        </div>
    );
}

    return (
        <div className='app'>
            <h1>What to do, what to do?</h1>
            <ul>
                {items.map(item => (
                    <li key={item._id} className={`item ${item.votes === maxVotes && maxVotes > 0 ? 'mostVoted' : ''}`}>
                        {item.name} - Votes: {item.votes}
                        &nbsp;<div className='actions'><button onClick={() => voteForItem(item._id)}>Vote</button>&nbsp;
                        <button onClick={() => deleteItem(item._id, item.votes)}>Delete</button></div>
                    </li>
                ))}
            </ul>
            <form onSubmit={addItem} className='addItemForm'>
                <input
                    type="text"
                    value={itemName}
                    onChange={e => setItemName(e.target.value)}
                    placeholder="Enter new item name"
                />
                <button type="submit" className='addItemButton'>Add Item</button>
            </form>
        </div>
    );
}

export default App;